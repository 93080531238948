<template>
  <div>
    <div class="pay-container" v-if="isWeChat()">
      <div>
        <div class="pay-header">
          <div>
            付款给企业
            <div class="company-name">({{ conBrand }}收银台)</div>
          </div>
        </div>
        <div class="pay-main">
          <div class="pay-money-title">付款金额</div>
          <div class="pay-input-wrapper">
            <span class="pay-icon">￥</span>
            <div class="pay-money" v-if="inputValue">{{ inputValue }}</div>
            <div class="pay-cursor" v-if="!inputValue"></div>
          </div>
        </div>
        <van-number-keyboard :show="true" theme="custom" extra-key="." close-button-text="完成" @input="onInput" @delete="onDelete" v-model="inputValue" @close="handleClose" />
      </div>
    </div>
    <div class="is-not-wx" v-else>请在微信内打开</div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant';
import { getBrand } from '@/api/cashDesk';
import { wcQrcodeSilenceAuth, fyCashierWxPay } from '@/api/wxCashDesk';
import $ from 'jquery';
Vue.use(Toast);
export default {
  props: {},
  data() {
    return {
      conBrand: '芙悦', //品牌名
      inputValue: '',
      curPageUrl: '',
      authCode: ''
    };
  },
  computed: {},
  created() {
    // new VConsole();
    if (this.$route.query.orderNo) {
      this.show = true;
    }
  },
  async mounted() {
    if (this.isWeChat()) {
      this.setCache();
      if (this.$route.query.code) {
        this.handleClose();
      }
    }
  },
  methods: {
    async setCache() {
      let mchId = this.$route.query.mchId;
      let username = this.$route.query.username;
      let team = this.$route.query.team;
      let sales_id = this.$route.query.sales_id;
      let conBrand = this.$route.query.conBrand;
      team && window.sessionStorage.setItem('team', team);
      sales_id && window.sessionStorage.setItem('sales_id', sales_id);
      mchId && window.sessionStorage.setItem('mchId', mchId);
      username && window.sessionStorage.setItem('username', username);
      const brands = await getBrand();
      if (this.$route.query.conBrand) {
        const conBrandName = brands.data.filter((item) => +item.id == +this.$route.query.conBrand)[0].name;
        window.sessionStorage.setItem('conBrand', conBrandName);
        this.conBrand = window.sessionStorage.getItem('conBrand');
      } else {
        const conBrandName = window.sessionStorage.getItem('conBrand');
        if (conBrandName) {
          this.conBrand = conBrandName;
        }
      }
      const price = window.sessionStorage.getItem('inputValue');
      price ? (this.inputValue = price) : (this.inputValue = '');
    },
    //判断微信环境
    isWeChat() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    onDelete() {
      console.log('删除');
      this.inputValue = this.inputValue.slice(0, -1);
    },
    onInput(value) {
      this.inputValue = String(value);
    },
    //预支付
    async beforePay() {
      let urls = window.location.href;
      const data = {
        pay_types: window.sessionStorage.getItem('pay_types'),
        team_id: window.sessionStorage.getItem('team'),
        sales_id: window.sessionStorage.getItem('sales_id'),
        isWeChat: this.isWeChat() == true ? 'wx' : '',
        openid: window.sessionStorage.getItem('openid'),
        mchId: window.sessionStorage.getItem('mchId'),
        url: encodeURIComponent(urls),
        price: window.sessionStorage.getItem('inputValue')
      };
      const result = await fyCashierWxPay(data);
      this.WXdata = result.data;
      console.log('appId', this.WXdata.appId);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.WXdata.appId, // 必填，公众号的唯一标识
        timestamp: this.WXdata.timeStamp, // 必填，生成签名的时间戳
        nonceStr: this.WXdata.nonceStr, // 必填，生成签名的随机串
        signature: this.WXdata.signature, // 必填，签名
        jsApiList: this.WXdata.jsApiList // 必填，需要使用的JS接口列表
      });
      this.WXdata.openids = this.openid;
      //校验浏览对微信api是否支持
      this.verification();
    },
    //校验浏览对微信api是否支持
    verification() {
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    onBridgeReady() {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: this.WXdata.appId, //公众号名称，由商户传入
          timeStamp: this.WXdata.timeStamp, //1970年以来的秒数
          nonceStr: this.WXdata.nonceStr, //随机串
          package: 'prepay_id=' + this.WXdata.prepay_id,
          signType: 'MD5', //微信签名方式：
          paySign: this.WXdata.paySign //微信签名
        },
        function (res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            Toast.success('支付成功');
          } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
            Toast.fail('已取消');
          } else if (res.err_msg == 'get_brand_wcpay_request:fail') {
            Toast.fail('支付失败');
          }
        }
      );
    },
    //判断微信环境
    isWeChat() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    getOpenid() {
      const that = this;
      if (this.openid) {
        return new Promise((resolve, reject) => {
          resolve({
            openid: window.sessionStorage.getItem('openid')
          });
        });
      } else {
        return new Promise((resolve, reject) => {
          $.ajax({
            type: 'POST',
            url: 'https://proceeds.yymijing.com/api/wcQrcodeGetAccessToken',
            data: { code: that.authCode, appid: window.sessionStorage.getItem('appid'), pay_types: window.sessionStorage.getItem('pay_types'), mchId: window.sessionStorage.getItem('mchId') },
            success: function (res) {
              resolve(res);
            }
          });
        });
      }
    },
    async handleClose() {
      console.log('完成', this.inputValue);
      let data = {
        url: window.location.href,
        pay_types: window.sessionStorage.getItem('pay_types'),
        team_id: window.sessionStorage.getItem('team'),
        sales_id: window.sessionStorage.getItem('sales_id'),
        openid: window.sessionStorage.getItem('openid'),
        mchId: window.sessionStorage.getItem('mchId'),
        isWeChat: this.isWeChat() == true ? 'wx' : ''
      };
      console.log('微信环境');
      if (!this.$route.query.code) {
        this.curPageUrl = decodeURIComponent(window.location.href);
        data.url = this.curPageUrl;
        wcQrcodeSilenceAuth(data)
          .then(async (resp) => {
            window.sessionStorage.setItem('inputValue', this.inputValue);
            window.sessionStorage.setItem('appid', resp.appid);
            window.sessionStorage.setItem('pay_types', resp.pay_types);
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + resp.appid + '&response_type=code&scope=snsapi_base&state=STATE' + '&redirect_uri=' + this.curPageUrl + '#wechat_redirect';
          })
          .catch((err) => {
            Toast(err);
          });
      } else {
        this.authCode = this.$route.query.code;
        this.getOpenid().then((res) => {
          console.log('getOpenid', typeof res);
          console.log('获取到的openid为', res.openid);
          this.openid = res.openid;
          window.sessionStorage.setItem('openid', res.openid);
          this.beforePay();
        });
      }
    }
  }
};
</script>

<style scoped lang="less">
.pay-container {
  padding: 0 31px;
  height: 100vh;
  background: #ffffff;
}
.pay-header {
  height: 130px;
  display: flex;
  align-items: center;
  .company-name {
    font-size: 12px;
    margin-top: 6px;
    color: #909399;
  }
}
.pay-money-title {
  font-size: 14px;
  text-align: left;
}
.pay-input-wrapper {
  margin-top: 6px;
  position: relative;
  display: flex;
  height: 50px;
  line-height: 50px;
  align-items: center;
}
.pay-input-wrapper:after {
  content: ' ';
  position: absolute;
  left: -50%;
  right: -50%;
  bottom: 0;
  border-bottom: 1px solid rgb(235, 237, 240);
  transform: scaleX(0.5);
}
.pay-icon,
.pay-money {
  font-size: 18px;
  font-weight: bold;
}
.pay-money {
  margin-right: 2px;
}
.pay-cursor {
  width: 3px;
  height: 33px;
  background: #1aad19;
  border-radius: 3px;
  animation: twinkling 1.5s infinite;
}
@keyframes twinkling {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.loading-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}

.loading-dialog .dialog-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading-dialog .dialog-content {
  position: relative;
  z-index: 10000;
  text-align: center;
  padding: 0.4rem 1.5rem;
}
.dialog-content {
  width: 80%;
  background-color: #fff;
  border-radius: 10px;
  .p2 {
    color: #ff2627;
  }
  .p1,
  .p2 {
    border-bottom: 1px solid #e7e7e7;
  }
}
.dialog-content p {
  padding: 15px 15px;
  font-size: 16px;
  text-align: center;
}
.is-not-wx {
  height: 100vh;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
